.contacts {
  display: flex;
  padding: 30px 50px;
}

.title {
  margin-left: 50px;
  margin-top: 30px;
}

.contacts iframe {
  flex-shrink: 0;
  flex-grow: 0;
  border: none;
}

.contact-info {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-info>* {
  text-align: center;
  margin-bottom: 20px;
}

.schedule-listener {
  display: none;
  font-weight: 400;
  padding: 10px;
  margin-bottom: 10px;
}

.mobile-link {
  display: none;
}

.open {
  color: white;
  background-color: green;
}

.closed {
  background-color: lightgray;
}

.warning {
  background-color: yellow;
}

.closed.show,
.open.show,
.closed-day-warning.show,
.closed-period-warning.show {
  display: block;
}


@media only screen and (max-width: 600px) {
  .contacts {
    text-align: center;
    flex-direction: column;
  }

  iframe {
    display: none;
  }

  .mobile-link {
    display: block;
  }
}