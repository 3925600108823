.news {
  background-color: #2d2a32;
  color: #f0f0f0;
  height: 100%;
  padding: 30px 50px;
}

.news-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.news a {
  color: #dddddd;
}

.news-article {
  width: 17%;
  text-align: center;
  margin-left: 20px;
}

.news-article:not(:nth-child(-n + 5)) {
  margin-top: 100px;
}

.news-article img {
  max-width: 300px;
  max-height: 200px;
}

.news-thumb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.news-thumb span {
  flex-shrink: 0;
  margin-top: 10px;
}

.news-article a {
  text-decoration: none;
  color: #f0f0f0;
}

.image-div {
  align-items: center;
}

.title {
  font-size: 200%;
}

@media only screen and (max-width: 600px) {
  .news-article {
    width: 100%;
  }
}