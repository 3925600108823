.brand-logo {
  display: inline-block;
  height: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffbc;
  flex-shrink: 0;
  color: #2d2a32;
  padding: 10px 10px;
  box-shadow: 0px 6px 6px 0px rgba(187, 187, 187, 0.29);
  z-index: 1000;
  flex-shrink: 0;
}

.right {
  text-align: right;
  display: flex;
}

.language {
  text-align: center;
  margin-left: 30px;
}

.language img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .header {
    text-align: center;
    flex-direction: column;
  }

  .brand-logo,
  .language,
  .info {
    margin-bottom: 30px;
    margin-left: 0;
  }

  .brand-logo img {
    height: 100px;
  }

  .right {
    text-align: center;
    flex-direction: column;
  }
}
