.servicos {
  padding: 50px;
}

.servicos ul {
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.servicos ul li {
  width: 25%;
  padding: 1%;
  height: 150px;
  background-color: #5579a6;
  margin-bottom: 40px;
  margin-right: 5%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}



@media only screen and (max-width: 600px) {
  

  .servicos ul li {
    width: 100%;
    height: auto;
    padding: 4%;
    margin-bottom: 10px;
  }

}