@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;700;800&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  height: 100%;
}

p {
  margin: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.cta {
  font-size: 150%;
  text-align: center;
}

.cta span {
  font-size: 200%;
}

main {
  flex: 1 0 auto;
  background-color: #ffffe5;
}


@media only screen and (max-width: 600px) {

  .cta span,
  .call-to-action span {
    display: block;
    font-size: 100%;
    font-weight: bold;
  }
}