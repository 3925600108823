.sobre-nos {
  background-color: #2d2a32;
  color: white;
  padding: 10px 50px;
}

.sobre-nos h3 {
  margin-top: 30px;
}

.statement p {
  margin: 10px;
}

.profile {
  display: flex;
  padding-left: 9%;
  align-items: center;
  margin-bottom: 50px;
}

.photo {
  display: inline-block;
  width: 20%;
  border-radius: 50%;
}

.photo-description {
  width: 50%;
  margin-left: 100px;
}

.statement-and-clients {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.statement {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: justify;
}

@media only screen and (max-width: 600px) {

  .statement-and-clients,
  .right,
  .profile {
    text-align: center;
    flex-direction: column;
  }

  .photo {
    width: 100%;
    margin-bottom: 20px;
  }

  .photo-description {
    width: 100%;
    margin: 0;
  }

  .statement,
  .clients {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

}