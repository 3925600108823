.footer {
  padding: 30px 50px;
  background-image: linear-gradient(#5477ae, #36496c);
  flex-shrink: 0;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.footer img {
  display: inline-block;
  height: 100%;
  width: 140px;
}

.footer img.facebook {
  display: inline-block;
  width: 20px;
  margin-left: 5px;
  height: auto;
}


@media only screen and (max-width: 600px) {

  footer .brand-name,
  footer .call-to-action,
  footer .facebook {
    text-align: center;
    margin-bottom: 20px;
  }

  footer img {
    max-width: 40%;
  }
}