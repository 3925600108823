.news-article-content {
  display: flex;
}

.news-article-content img {
  max-width: 50%;
}

.rich-text {
  margin-left: 10px;
  width: 100%;
}

.news-article {
  background-color: #dddddd;
  padding: 20px;
  margin-top: 20px;
  color: #2d2a32;
}

.main {
  background-color: #2d2a32;
  padding: 30px 50px;
  height: 100%;
}

.main a {
  color: #dddddd;
}

.main .news-article a {
  color: #2d2a32;
}

p {
  min-height: 30px;
  margin-bottom: 10px;
}

.title {
  font-size: 200%;
  margin-bottom: 20px;
}

.paragraph {
  margin-top: 20px;
}
