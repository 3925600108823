@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;700;800&display=swap);
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  height: 100%;
}

p {
  margin: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.cta {
  font-size: 150%;
  text-align: center;
}

.cta span {
  font-size: 200%;
}

main {
  flex: 1 0 auto;
  background-color: #ffffe5;
}


@media only screen and (max-width: 600px) {

  .cta span,
  .call-to-action span {
    display: block;
    font-size: 100%;
    font-weight: bold;
  }
}
.Footer_footer__1xiH2 {
  padding: 30px 50px;
  background-image: linear-gradient(#5477ae, #36496c);
  flex-shrink: 0;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.Footer_footer__1xiH2 img {
  display: inline-block;
  height: 100%;
  width: 140px;
}

.Footer_footer__1xiH2 img.Footer_facebook__1rXIF {
  display: inline-block;
  width: 20px;
  margin-left: 5px;
  height: auto;
}


@media only screen and (max-width: 600px) {

  footer .Footer_brand-name__11NMe,
  footer .Footer_call-to-action__1HNmj,
  footer .Footer_facebook__1rXIF {
    text-align: center;
    margin-bottom: 20px;
  }

  footer img {
    max-width: 40%;
  }
}
main {
  flex: 1 1;
}
.Header_brand-logo__1Qpvr {
  display: inline-block;
  height: 50px;
}

.Header_header__3H2Qn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffbc;
  flex-shrink: 0;
  color: #2d2a32;
  padding: 10px 10px;
  box-shadow: 0px 6px 6px 0px rgba(187, 187, 187, 0.29);
  z-index: 1000;
  flex-shrink: 0;
}

.Header_right__1flIa {
  text-align: right;
  display: flex;
}

.Header_language__2KAhH {
  text-align: center;
  margin-left: 30px;
}

.Header_language__2KAhH img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Header_header__3H2Qn {
    text-align: center;
    flex-direction: column;
  }

  .Header_brand-logo__1Qpvr,
  .Header_language__2KAhH,
  .Header_info__3_Eks {
    margin-bottom: 30px;
    margin-left: 0;
  }

  .Header_brand-logo__1Qpvr img {
    height: 100px;
  }

  .Header_right__1flIa {
    text-align: center;
    flex-direction: column;
  }
}

.Services_servicos__1zE1W {
  padding: 50px;
}

.Services_servicos__1zE1W ul {
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.Services_servicos__1zE1W ul li {
  width: 25%;
  padding: 1%;
  height: 150px;
  background-color: #5579a6;
  margin-bottom: 40px;
  margin-right: 5%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}



@media only screen and (max-width: 600px) {
  

  .Services_servicos__1zE1W ul li {
    width: 100%;
    height: auto;
    padding: 4%;
    margin-bottom: 10px;
  }

}
.Contacts_contacts__25_Vl {
  display: flex;
  padding: 30px 50px;
}

.Contacts_title__1SGEu {
  margin-left: 50px;
  margin-top: 30px;
}

.Contacts_contacts__25_Vl iframe {
  flex-shrink: 0;
  flex-grow: 0;
  border: none;
}

.Contacts_contact-info__28gVZ {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Contacts_contact-info__28gVZ>* {
  text-align: center;
  margin-bottom: 20px;
}

.Contacts_schedule-listener__1CBX7 {
  display: none;
  font-weight: 400;
  padding: 10px;
  margin-bottom: 10px;
}

.Contacts_mobile-link__2RNJB {
  display: none;
}

.Contacts_open__2dlNP {
  color: white;
  background-color: green;
}

.Contacts_closed__2196g {
  background-color: lightgray;
}

.Contacts_warning___wXVA {
  background-color: yellow;
}

.Contacts_closed__2196g.Contacts_show__3AZ2p,
.Contacts_open__2dlNP.Contacts_show__3AZ2p,
.Contacts_closed-day-warning__3is9_.Contacts_show__3AZ2p,
.Contacts_closed-period-warning__tPo4e.Contacts_show__3AZ2p {
  display: block;
}


@media only screen and (max-width: 600px) {
  .Contacts_contacts__25_Vl {
    text-align: center;
    flex-direction: column;
  }

  iframe {
    display: none;
  }

  .Contacts_mobile-link__2RNJB {
    display: block;
  }
}
.AboutUs_sobre-nos__1wJ5u {
  background-color: #2d2a32;
  color: white;
  padding: 10px 50px;
}

.AboutUs_sobre-nos__1wJ5u h3 {
  margin-top: 30px;
}

.AboutUs_statement__387mO p {
  margin: 10px;
}

.AboutUs_profile__1Zb2C {
  display: flex;
  padding-left: 9%;
  align-items: center;
  margin-bottom: 50px;
}

.AboutUs_photo__VvtqS {
  display: inline-block;
  width: 20%;
  border-radius: 50%;
}

.AboutUs_photo-description__2G4Kw {
  width: 50%;
  margin-left: 100px;
}

.AboutUs_statement-and-clients__JRTh6 {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.AboutUs_statement__387mO {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: justify;
}

@media only screen and (max-width: 600px) {

  .AboutUs_statement-and-clients__JRTh6,
  .AboutUs_right__2noGJ,
  .AboutUs_profile__1Zb2C {
    text-align: center;
    flex-direction: column;
  }

  .AboutUs_photo__VvtqS {
    width: 100%;
    margin-bottom: 20px;
  }

  .AboutUs_photo-description__2G4Kw {
    width: 100%;
    margin: 0;
  }

  .AboutUs_statement__387mO,
  .AboutUs_clients__1MBa7 {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

}
.Hero_left__It0Bc span {
  font-size: 400%;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #2d2a32;
}

.Hero_news__FltVt {
  position: absolute;
  top: 10%;
  right: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: #d9d6d6;
  right: -1000px;
  padding: 10px;
  box-shadow: 0px 6px 6px 0px rgba(51, 49, 49, 0.29);
  transition: transform linear 1000ms;
}

.Hero_destaque__1bLCZ {
  display: flex;
  justify-content: space-between;
}

.Hero_destaque__1bLCZ a {
  font-size: 90%;
  color: #010101;
}

.Hero_destaque__1bLCZ span {
  margin-bottom: 10px;
  color: #010101;
  font-weight: 400;
}

.Hero_left__It0Bc {
  font-size: 300%;
  padding-left: 40%;
}

.Hero_hero__1jY5r {
  display: flex;
  position: relative;
  align-items: center;
  background-image: url(/static/media/calculator.ec8cab33.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 48px);
  width: auto;
  text-align: center;
  overflow: hidden;
}

.Hero_saiba-mais__9KC7M {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 5%;
  left: 45%;
  background-color: #5579a6;
  color: white;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 11px 17px -4px rgba(0, 0, 0, 0.75);
  animation-name: Hero_jump__VtHG3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.Hero_news__FltVt.Hero_show__3Oq4E {
  z-index: 1000;
  transform: translate(-1000px);
}

.Hero_saiba-mais__9KC7M img {
  display: inline-block;
  height: 15px;
  margin-left: 10px;
}

@keyframes Hero_jump__VtHG3 {

  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.Hero_jump__VtHG3 {
  animation-name: Hero_bounce__3KCaW;
  transform-origin: center bottom;
}


@media only screen and (max-width: 600px) {

  .Hero_left__It0Bc {
    font-size: 200%;
    padding-left: 30%;
  }

  .Hero_saiba-mais__9KC7M {
    display: none;
  }

  .Hero_news__FltVt {
    top: 10%;
    display: flex;
    flex-direction: column;
    transition: transform linear 3000ms;
  }

}
.HeroNews_news__20ytV {
  display: flex;
}

.HeroNews_news-article__2JcDD {
  width: 250px;
  text-align: center;
  margin-left: 20px;
}

.HeroNews_news-article__2JcDD img {
  max-width: 100px;
  max-height: 200px;
}

.HeroNews_news-thumb__1P41q {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.HeroNews_news-thumb__1P41q span {
  flex-shrink: 0;
  margin-bottom: 10px;
}

.HeroNews_news-article__2JcDD a {
  text-decoration: none;
  color: #383636;
}

.HeroNews_image-div__3lI5q {
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .HeroNews_news__20ytV {
    flex-direction: column;
  }
}
.NewsArticle_news-article-content__3KLFT {
  display: flex;
}

.NewsArticle_news-article-content__3KLFT img {
  max-width: 50%;
}

.NewsArticle_rich-text__2t7aH {
  margin-left: 10px;
  width: 100%;
}

.NewsArticle_news-article__3c6Vi {
  background-color: #dddddd;
  padding: 20px;
  margin-top: 20px;
  color: #2d2a32;
}

.NewsArticle_main__3K9SF {
  background-color: #2d2a32;
  padding: 30px 50px;
  height: 100%;
}

.NewsArticle_main__3K9SF a {
  color: #dddddd;
}

.NewsArticle_main__3K9SF .NewsArticle_news-article__3c6Vi a {
  color: #2d2a32;
}

p {
  min-height: 30px;
  margin-bottom: 10px;
}

.NewsArticle_title__1xaTW {
  font-size: 200%;
  margin-bottom: 20px;
}

.NewsArticle_paragraph__i2gic {
  margin-top: 20px;
}

.News_news__3hd8z {
  background-color: #2d2a32;
  color: #f0f0f0;
  height: 100%;
  padding: 30px 50px;
}

.News_news-list__3_Ya8 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.News_news__3hd8z a {
  color: #dddddd;
}

.News_news-article__3K0K7 {
  width: 17%;
  text-align: center;
  margin-left: 20px;
}

.News_news-article__3K0K7:not(:nth-child(-n + 5)) {
  margin-top: 100px;
}

.News_news-article__3K0K7 img {
  max-width: 300px;
  max-height: 200px;
}

.News_news-thumb__36MMA {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.News_news-thumb__36MMA span {
  flex-shrink: 0;
  margin-top: 10px;
}

.News_news-article__3K0K7 a {
  text-decoration: none;
  color: #f0f0f0;
}

.News_image-div__1vmwY {
  align-items: center;
}

.News_title__3wYpI {
  font-size: 200%;
}

@media only screen and (max-width: 600px) {
  .News_news-article__3K0K7 {
    width: 100%;
  }
}
