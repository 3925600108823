.news {
  display: flex;
}

.news-article {
  width: 250px;
  text-align: center;
  margin-left: 20px;
}

.news-article img {
  max-width: 100px;
  max-height: 200px;
}

.news-thumb {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.news-thumb span {
  flex-shrink: 0;
  margin-bottom: 10px;
}

.news-article a {
  text-decoration: none;
  color: #383636;
}

.image-div {
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .news {
    flex-direction: column;
  }
}