.left span {
  font-size: 400%;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #2d2a32;
}

.news {
  position: absolute;
  top: 10%;
  right: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: #d9d6d6;
  right: -1000px;
  padding: 10px;
  box-shadow: 0px 6px 6px 0px rgba(51, 49, 49, 0.29);
  transition: transform linear 1000ms;
}

.destaque {
  display: flex;
  justify-content: space-between;
}

.destaque a {
  font-size: 90%;
  color: #010101;
}

.destaque span {
  margin-bottom: 10px;
  color: #010101;
  font-weight: 400;
}

.left {
  font-size: 300%;
  padding-left: 40%;
}

.hero {
  display: flex;
  position: relative;
  align-items: center;
  background-image: url("../../images/calculator.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 48px);
  width: auto;
  text-align: center;
  overflow: hidden;
}

.saiba-mais {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 5%;
  left: 45%;
  background-color: #5579a6;
  color: white;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 11px 17px -4px rgba(0, 0, 0, 0.75);
  animation-name: jump;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.news.show {
  z-index: 1000;
  transform: translate(-1000px);
}

.saiba-mais img {
  display: inline-block;
  height: 15px;
  margin-left: 10px;
}

@keyframes jump {

  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.jump {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}


@media only screen and (max-width: 600px) {

  .left {
    font-size: 200%;
    padding-left: 30%;
  }

  .saiba-mais {
    display: none;
  }

  .news {
    top: 10%;
    display: flex;
    flex-direction: column;
    transition: transform linear 3000ms;
  }

}